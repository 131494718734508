<div class="container-fluid bg-dark">
  <header class='container'>
    <div class="row">
      <nav class="col-12 navbar navbar-expand navbar-dark bg-dark">
        <a class="navbar-brand"  [routerLink]="Is9Box ? ['/9box'] : ['/ampp/analysis']" ><i class="fa fa-home"></i> AMPP</a>
        <div class="collapse navbar-collapse">
          <div class="source" *ngIf="authenticated && !Is9Box">
            <div class="sourceName">BHI</div>
            <div class="sourceUpdated">(updated {{bhiDate}})</div>
          </div>
          <div class="source" *ngIf="authenticated && !Is9Box">
            <div class="sourceName">Zonda</div>
            <div class="sourceUpdated">(updated {{zondaDate}})</div>
          </div>
        </div>
        <form class="form-inline my-2 my-lg-0">
          <ul *ngIf="authenticated" class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="#">{{ adalService.userInfo.profile.given_name + ' ' + adalService.userInfo.profile.family_name }}</a></li>
          </ul>
          <input *ngIf="!authenticated" (click)="login()" type="button" class="btn btn-primary" value="Login">
          <input *ngIf="authenticated" (click)="logout()" type="button" class="btn sm btn-default" value="Logout">
        </form>
      </nav>
    </div>
  </header>
</div>
