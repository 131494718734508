import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ResponseType } from '../ampp-module/models/community.model'
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private division: number = 0;
  private msa: number = 0;
  private submarket: number = 0;
  public locations: any;
  public AnalysisTypes:any;
  divisions: any[];
  msas: any[];
  submarkets: any[];
  analysisID: number = 0;
  pultebrands: any[];
  competitorbrands: any[];
  constructor(public http: HttpClient) { }
  setDivision(division: number) {
    //this.division = division;
    localStorage.setItem("division", division.toString())
  }
  getDivision() {
    this.division = parseInt(localStorage.getItem("division"))
    if (!Number.isNaN(parseInt(localStorage.getItem("division")))) {
      return parseInt(localStorage.getItem("division"));
    }
    else {
      return 0;
    }
  }

  getmsa() {
    return this.msa;
  }

  setmsa(msa: number) {
    this.msa = msa;
  }

  getsubmarket() {
    return this.submarket;
  }

  setsubmarket(sub: number) {
    this.submarket = sub;
  }

  private summaryRefreshSource = new BehaviorSubject(false);
	SumaryRefresh = this.summaryRefreshSource.asObservable();

  setSummaryRefresh(status:boolean) {
    this.summaryRefreshSource.next(status);
  }

  public getLocations() {
    let url = environment.apiUrl + 'DivisionViewModels?$filter=SnapshotID eq 0';
    return this.http.get<ResponseType>(url).pipe(map(data => {

      this.locations = data.value;
      return data.value;
    })
    );
  }


  public getAnalysisTypes() {
    let url = environment.apiUrl + 'AnalysisTypes';
    return this.http.get<ResponseType>(url).pipe(map(data => {
      this.AnalysisTypes = data.value;
      return data.value;
    })
    );
  }

  public getDivisions() {
    var uniquediv = new Array();
    var ids = new Array();

    for (let division of this.locations) {
      if (ids.indexOf(division.DivisionID) === -1) {
        uniquediv.push(division);
        ids.push(division.DivisionID);
      }
    }
    //sorting Division name A-Z
    uniquediv.sort(function (a, b) {
      var nameA = a.DivisionName.toUpperCase(); // ignore upper and lowercase
      var nameB = b.DivisionName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    let index = uniquediv.filter(a => a.DivisionName.toUpperCase().startsWith('OUTSIDE'));
    index.forEach(a => {
      let index = uniquediv.find(b => b.DivisionID == a.DivisionID);
      uniquediv.push(uniquediv.splice(uniquediv.indexOf(index), 1)[0]);
    });
    return uniquediv;
  }

  public getMSA(divisionId) {
    var allmsas = this.locations.filter((item) => item.DivisionID == divisionId);
    var uniqueMSA = new Array();
    var ids = new Array();

    for (let msa of allmsas) {
      if (ids.indexOf(msa.MSAID) === -1) {
        uniqueMSA.push(msa);
        ids.push(msa.MSAID);
      }
    }
    uniqueMSA.sort(function (a, b) {
      var nameA = a.MSAName.toUpperCase(); // ignore upper and lowercase
      var nameB = b.MSAName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    let index = uniqueMSA.filter(a => a.MSAName.toUpperCase().startsWith('OUTSIDE'));
    index.forEach(a => {
      let index = uniqueMSA.find(b => b.MSAID == a.MSAID);
      uniqueMSA.push(uniqueMSA.splice(uniqueMSA.indexOf(index), 1)[0]);
    });
    return uniqueMSA;
  }
  public getSubmarkets(msaId) {
    var allsubmarkets = this.locations.filter((item) => item.MSAID == msaId);
    var uniqueSub = new Array();
    var ids = new Array();

    for (let submarket of allsubmarkets) {
      if (ids.indexOf(submarket.SubMarketID) === -1) {
        uniqueSub.push(submarket);
        ids.push(submarket.SubMarketID);
      }
    }
    uniqueSub.sort(function (a, b) {
      var nameA = a.SubMarketName.toUpperCase(); // ignore upper and lowercase
      var nameB = b.SubMarketName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    let index = uniqueSub.filter(a => a.SubMarketName.toUpperCase().startsWith('OUTSIDE'));
    index.forEach(a => {
      let index = uniqueSub.find(b => b.SubMarketID == a.SubMarketID);
      uniqueSub.push(uniqueSub.splice(uniqueSub.indexOf(index), 1)[0]);
    });
    return uniqueSub;
  }


  //TODO : Change this to function all
  public getPulteBrands() {
    let url = environment.apiUrl + "Builders/API.PulteBuilders()";
    console.log(url)
    return this.http.get<ResponseType>(url).pipe(map(data => {
      this.pultebrands = data.value;
      return data.value;
    })
    )
      .pipe(catchError(error => {
        return Observable.throw(error)
      }))
  }

  //TODO: Rename to builders collection
  public getCompetitorBrands() {
    let url = environment.apiUrl + "Builders";
    console.log(url)
    return this.http.get<ResponseType>(url).pipe(map(data => {
      this.competitorbrands = data.value;
      return data.value;
    })
    )
      .pipe(catchError(error => {
        return Observable.throw(error)
      }))
  }

  public getSources() {
    let url = environment.apiUrl + "DataSourceViewModel";
    console.log(url)
    return this.http.get<ResponseType>(url).pipe(map(data => {
      return data.value;
    })
    )
      .pipe(catchError(error => {
        return Observable.throw(error)
      }))
  }



}
