// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   apiUrl: 'https://amppapi.dev.pulte.com/odata/',
   azureAuthProvider: {
     'clientId': '6944f3ea-d619-4a72-a318-f7acea551b4b',
     'tenant': 'pulte.onmicrosoft.com',
     redirectUri: 'https://ampp.dev.pulte.com/login',
     postLogoutRedirectUri: 'https://ampp.dev.pulte.com/logout'
   },
   envName: 'dev',
  production: false,
   applicationInsights: {
     instrumentationKey: '45d5bc00-be13-4050-91d2-5121ec028e07'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
