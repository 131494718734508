import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ResponseType } from '../models/community.model'
import { Admin } from '../models/admin.model';


@Injectable()
export class AdminService {

	constructor(private http : HttpClient) { }

  getAdmins(){
		let url = environment.apiUrl + "Admins";
		console.log(url);
		return this.http.get<ResponseType>(url).pipe(map(response => {
			return response;
		}));
	}

  deleteAdmin(id)
  {
    let url = environment.apiUrl + "Admins(" + id + ")";
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			})
		};
		return this.http.delete(url, httpOptions).pipe(map(response => {
			return response;

		}))
  }

  AddAdmin(input){
		let url = environment.apiUrl + "Admins";
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Accept': 'application/json'
			})
		};
      return this.http.post<Admin>(url,input,httpOptions).pipe(map(response =>
      {
			return response;

		}))

	}
}
