<div class="breadcrumb-wrapper container-fluid no-gutters">
  <div class="container">
    <nav class="row">
      <ol class="breadcrumb col-10">
        <!-- <li class="breadcrumb-item"><a routerLink="">Home</a></li> -->
        <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs">
          <a *ngIf="breadcrumb.url.length" [routerLink]="[breadcrumb.url]" >{{breadcrumb.label}}</a>
          <span *ngIf="!breadcrumb.url.length">{{breadcrumb.label}}</span>
        </li>


      </ol>
<ol class="breadcrumb col-2">

  <a href="https://pulte.sharepoint.com/teams/AMPP/_layouts/15/Doc.aspx?sourcedoc={3bfc4731-fd90-46aa-8e80-63bb676ce60a}&action=edit&wd=target%28Accessing%20the%20AMPP%20Data%20Interface%20Tool.one%7C9a4ca934-759c-41af-89b2-445b5760ef58%2fLogging%20In%7C7de9e772-44a6-4ce8-9dc8-a902b1a7cee2%2f%29" target="_blank">
    <u>AMPP User Guide</u>
  </a>
  <a  class="pl-2"  *ngIf= "isAdmin" routerLink="/ampp/admin" >
    <u>ADMIN Only</u>
  </a>
</ol>
    </nav>
  </div>
</div>

